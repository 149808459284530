import { useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import { config } from "./config";
import { KeypressType, useKeyPress } from "./hooks/useKeyPress"
import { handleFall, handleJump, handleMoveLeft, handleMoveRight } from "./movement";

const Viewport = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  font-family: sans-serif;
`

const Scene = styled.div<{
  width: number,
  height: number
}>`
  z-index: -1;
  position: absolute;
  transition: ${1 / config.fps}s ease;

  ${props => (`
    width: ${(props.width + 1) * config.blockSize}px;
    height: ${props.height * config.blockSize}px;
  `)}
`

const Player = styled.span<{
  height: number,
  width: number
}>`
  ${props => (`
    display: block;
    width: ${config.blockSize * props.width}px;
    height: ${config.blockSize * props.height}px;
    background: black;
    border-radius: 10px;
    position: absolute;
    z-index: 10;
    transition: ${1 / config.fps}s ease;
  `)}
`

const Obstacle = styled.span<{
  x: number,
  y: number
}>`
  display: block;
  width: ${config.blockSize}px;
  height: ${config.blockSize}px;
  position: absolute;
  background: brown;
  bottom: ${props => (props.y - 1) * config.blockSize}px;
  left: ${props => (props.x - 1) * config.blockSize}px;
  border: 2px solid black;
  box-sizing: border-box;
`

const Collectable = styled.span<{
  x: number,
  y: number
}>`
  display: block;
  width: ${config.blockSize * 0.7}px;
  height: ${config.blockSize * 0.7}px;
  border-radius: 100px;
  position: absolute;
  bottom: ${props => ((props.y - 1) * config.blockSize) + (config.blockSize * 0.15)}px;
  left: ${props => ((props.x - 1) * config.blockSize) + (config.blockSize * 0.15)}px;
  border: 4px solid gold;
  box-sizing: border-box;
`

const Score = styled.p`
  font-size: 22px;
  font-weight: 600;
  position: fixed;
  left: 60px;
  top: 60px;
  color: gold;
`

const FinishLine = styled.span<{
  x: number
}>`
  ${props => (`
    width: ${config.blockSize}px;
    height: 100%;
    position: absolute;
    left: ${(props.x - 1) * config.blockSize}px;
    top: 0;
    background: red;
    opacity: 0.7;
  `)}
`

const LevelComplete = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  color: gold;
  font-weight: 600;
  z-index: 20;
`

export type CoordinatesType = {
  x: number,
  y: number
}

export type PlayerType = {
  width: number,
  height: number
}

export type CollectableType = CoordinatesType & {
  collected: boolean
}

export type ObstacleType = CoordinatesType

export type FrameDataType = {
  playerPosition: CoordinatesType,
  scenePosition: CoordinatesType,
  collectables: Array<CollectableType>
}

export type SceneDataType = {
  maxRight: number,
  maxLeft: number,
  fpsMultiplyer: number,
  sceneWidth: number,
  sceneHeight: number,
  obstacles: Array<ObstacleType>,
  sprinting: KeypressType,
  player: PlayerType
}

type SceneType = {
  width: number,
  height: number,
  finishLine: number,
  obstacles: Array<ObstacleType>,
  collectables: Array<CoordinatesType>
}

const player: PlayerType = { width: 1, height: 2 };

export const Platformer = () => {
  const jumping = useKeyPress(config.keybinds.jump);
  const movingLeft = useKeyPress(config.keybinds.left);
  const movingRight = useKeyPress(config.keybinds.right);
  const sprinting = useKeyPress(config.keybinds.sprint);

  const scene = useMemo<SceneType>(() => ({
    width: 60,
    height: 20,
    finishLine: 58,
    obstacles: [
      // Floor
      { x: 1, y: 1 },
      { x: 2, y: 1 },
      { x: 3, y: 1 },
      { x: 4, y: 1 },
      { x: 5, y: 1 },
      { x: 6, y: 1 },
      { x: 7, y: 1 },
      { x: 8, y: 1 },
      { x: 9, y: 1 },
      { x: 10, y: 1 },
      { x: 11, y: 1 },
      { x: 12, y: 1 },
      { x: 13, y: 1 },
      { x: 14, y: 1 },
      { x: 15, y: 1 },
      { x: 16, y: 1 },
      { x: 17, y: 1 },
      { x: 18, y: 1 },
      { x: 19, y: 1 },
      { x: 20, y: 1 },
      { x: 21, y: 1 },
      { x: 22, y: 1 },
      { x: 23, y: 1 },
      { x: 24, y: 1 },
      { x: 25, y: 1 },
      { x: 26, y: 1 },
      { x: 27, y: 1 },
      { x: 28, y: 1 },
      { x: 29, y: 1 },
      { x: 30, y: 1 },
      { x: 31, y: 1 },
      { x: 32, y: 1 },
      { x: 33, y: 1 },
      { x: 34, y: 1 },
      { x: 35, y: 1 },
      { x: 36, y: 1 },
      { x: 37, y: 1 },
      { x: 38, y: 1 },
      { x: 39, y: 1 },
      { x: 40, y: 1 },
      { x: 41, y: 1 },
      { x: 42, y: 1 },
      { x: 43, y: 1 },
      { x: 44, y: 1 },
      { x: 45, y: 1 },
      { x: 46, y: 1 },
      { x: 47, y: 1 },
      { x: 48, y: 1 },
      { x: 49, y: 1 },
      { x: 50, y: 1 },
      { x: 51, y: 1 },
      { x: 52, y: 1 },
      { x: 53, y: 1 },
      { x: 54, y: 1 },
      { x: 55, y: 1 },
      { x: 56, y: 1 },
      { x: 57, y: 1 },
      { x: 58, y: 1 },
      { x: 59, y: 1 },
      { x: 60, y: 1 },

      // H Pillar 1
      { x: 3, y: 3 },
      { x: 4, y: 3 },
      { x: 5, y: 3 },
      { x: 6, y: 3 },
      { x: 7, y: 3 },

      // V Pilar 1
      { x: 15, y: 2 },
      { x: 15, y: 3 },
      { x: 15, y: 4 },
      { x: 15, y: 5 },

      // H Pillar 2
      { x: 17, y: 6 },
      { x: 18, y: 6 },
      { x: 19, y: 6 },
      { x: 20, y: 6 },
      { x: 21, y: 6 },
      { x: 22, y: 6 },

      // V Piller 2
      { x: 24, y: 2 },
      { x: 24, y: 3 },
      { x: 24, y: 4 },
      { x: 24, y: 5 },

      // Pyramid
      { x: 33, y: 2 },
      { x: 34, y: 2 },
      { x: 35, y: 2 },
      { x: 36, y: 2 },
      { x: 37, y: 2 },
      { x: 38, y: 2 },
      { x: 39, y: 2 },
      { x: 40, y: 2 },
      { x: 41, y: 2 },
      { x: 42, y: 2 },
      { x: 43, y: 2 },
      { x: 44, y: 2 },
      { x: 45, y: 2 },
      { x: 46, y: 2 },
      { x: 47, y: 2 },
      { x: 48, y: 2 },

      { x: 34, y: 3 },
      { x: 35, y: 3 },
      { x: 36, y: 3 },
      { x: 37, y: 3 },
      { x: 38, y: 3 },
      { x: 39, y: 3 },
      { x: 40, y: 3 },
      { x: 41, y: 3 },
      { x: 42, y: 3 },
      { x: 43, y: 3 },
      { x: 44, y: 3 },
      { x: 45, y: 3 },
      { x: 46, y: 3 },
      { x: 47, y: 3 },

      { x: 35, y: 4 },
      { x: 36, y: 4 },
      { x: 37, y: 4 },
      { x: 38, y: 4 },
      { x: 39, y: 4 },
      { x: 40, y: 4 },
      { x: 41, y: 4 },
      { x: 42, y: 4 },
      { x: 43, y: 4 },
      { x: 44, y: 4 },
      { x: 45, y: 4 },
      { x: 46, y: 4 },

      { x: 36, y: 5 },
      { x: 37, y: 5 },
      { x: 38, y: 5 },
      { x: 39, y: 5 },
      { x: 40, y: 5 },
      { x: 41, y: 5 },
      { x: 42, y: 5 },
      { x: 43, y: 5 },
      { x: 44, y: 5 },
      { x: 45, y: 5 },

      { x: 37, y: 6 },
      { x: 38, y: 6 },
      { x: 39, y: 6 },
      { x: 40, y: 6 },
      { x: 41, y: 6 },
      { x: 42, y: 6 },
      { x: 43, y: 6 },
      { x: 44, y: 6 },

      { x: 38, y: 7 },
      { x: 39, y: 7 },
      { x: 40, y: 7 },
      { x: 41, y: 7 },
      { x: 42, y: 7 },
      { x: 43, y: 7 },

      { x: 39, y: 8 },
      { x: 40, y: 8 },
      { x: 41, y: 8 },
      { x: 42, y: 8 },

      { x: 40, y: 9 },
      { x: 41, y: 9 },
    ],
    collectables: [
      { x: 17, y: 10 },
      { x: 18, y: 10 },
      { x: 19, y: 10 },
      { x: 20, y: 10 },
      { x: 21, y: 10 },
      { x: 22, y: 10 },

      { x: 17, y: 11 },
      { x: 18, y: 11 },
      { x: 19, y: 11 },
      { x: 20, y: 11 },
      { x: 21, y: 11 },
      { x: 22, y: 11 },

      { x: 16, y: 2 },
      { x: 17, y: 2 },
      { x: 18, y: 2 },
      { x: 19, y: 2 },
      { x: 20, y: 2 },
      { x: 21, y: 2 },
      { x: 22, y: 2 },
      { x: 23, y: 2 },
      { x: 16, y: 3 },
      { x: 17, y: 3 },
      { x: 18, y: 3 },
      { x: 19, y: 3 },
      { x: 20, y: 3 },
      { x: 21, y: 3 },
      { x: 22, y: 3 },
      { x: 23, y: 3 },
      { x: 16, y: 4 },
      { x: 17, y: 4 },
      { x: 18, y: 4 },
      { x: 19, y: 4 },
      { x: 20, y: 4 },
      { x: 21, y: 4 },
      { x: 22, y: 4 },
      { x: 23, y: 4 },
      { x: 16, y: 5 },
      { x: 17, y: 5 },
      { x: 18, y: 5 },
      { x: 19, y: 5 },
      { x: 20, y: 5 },
      { x: 21, y: 5 },
      { x: 22, y: 5 },
      { x: 23, y: 5 },
    ]
  }), []);

  const sceneData = useMemo<SceneDataType>(() => ({
    maxRight: window.innerWidth * 0.75,
    maxLeft: window.innerHeight * 0.25,
    fpsMultiplyer: config.speedBaseFps / config.fps,
    sceneWidth: (config.blockSize * scene.width) - config.blockSize,
    sceneHeight: (config.blockSize * scene.height) - (config.blockSize * 2),
    sprinting,
    obstacles: scene.obstacles,
    player
  }), [sprinting, scene])

  const [playerPosition, setPlayerPosition] = useState<CoordinatesType>({ x: config.blockSize * 8, y: config.blockSize * 1 })
  const [scenePosition, setScenePosition] = useState<CoordinatesType>({ x: 0, y: 0 })
  const [collectables, setCollectables] = useState<Array<CollectableType>>(scene.collectables.map(c => ({ ...c, collected: false })))
  const [levelComplete, setLevelComplete] = useState(false)

  useEffect(() => {
    if (jumping.active) {
      // TODO: jump
    }
  }, [jumping.active])

  // Rendering frames
  useEffect(() => {
    const interval = setInterval(() => {
      let frameData: FrameDataType = {
        playerPosition: { ...playerPosition },
        scenePosition: { ...scenePosition },
        collectables: [...collectables],
      }

      // Hit the finish line?
      if (Math.ceil(frameData.playerPosition.x / config.blockSize) + 1 >= scene.finishLine) {
        setLevelComplete(true)
      }

      if (movingLeft.active) {
        frameData = handleMoveLeft(frameData, sceneData)
      }

      if (movingRight.active) {
        frameData = handleMoveRight(frameData, sceneData)
      }

      if (jumping.active && (new Date().getTime() - jumping.pressedAt) < 350) {
        frameData = handleJump(frameData, sceneData)
      } else {
        frameData = handleFall(frameData, sceneData)
      }

      setPlayerPosition(frameData.playerPosition)
      setScenePosition(frameData.scenePosition)
      setCollectables(frameData.collectables)
    }, (1000 / config.fps))

    return () => {
      clearInterval(interval)
    }
  }, [
    jumping,
    movingLeft,
    movingRight,
    sprinting,
    playerPosition,
    scenePosition,
    collectables,
    sceneData,
    scene
  ])

  return (
    <Viewport>
      <Scene
        {...scene}
        style={{
          left: scenePosition.x,
          bottom: scenePosition.y
        }}
      >
        <Score>Coins: {collectables.filter(c => c.collected).length}</Score>

        <Player
          {...player}
          style={{
            left: playerPosition.x,
            bottom: playerPosition.y
          }}
        />

        {scene.obstacles.map((obj) => (
          <Obstacle
            {...obj}
            key={`obstacle-${obj.x}-${obj.y}`}
          />
        ))}

        {collectables.map((obj) => {
          if (obj.collected) return null

          return (
            <Collectable
              {...obj}
              key={`collectable-${obj.x}-${obj.y}`}
            />
          )
        })}

        <FinishLine x={scene.finishLine} />

        {levelComplete && (
          <LevelComplete>
            Level Complete
          </LevelComplete>
        )}
      </Scene>
    </Viewport>
  )
}

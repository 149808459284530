export type ConfigType = {
  fps: number,
  speedBaseFps: number,
  speed: {
    walking: number,
    sprinting: number,
    jumping: number,
    sprintJumping: number,
    falling: number
  },
  blockSize: number,
  keybinds: {
    left: string,
    right: string,
    jump: string,
    sprint: string,
  }
}

export const config: ConfigType = {
  fps: 60,
  speedBaseFps: 30,
  speed: {
    walking: 30,
    sprinting: 45,
    jumping: 50,
    sprintJumping: 65,
    falling: 50
  },
  blockSize: 44,
  keybinds: {
    left: "KeyA",
    right: "KeyD",
    jump: "Space",
    sprint: "ShiftLeft"
  }
}

import { config } from "./config";
import { FrameDataType, SceneDataType } from "./Platformer";

export const handleMoveLeft: (
  frameData: FrameDataType,
  sceneData: SceneDataType,
) => FrameDataType = ({
  playerPosition,
  scenePosition,
  collectables
}, {
  maxLeft,
  fpsMultiplyer,
  obstacles,
  sprinting,
  player
}) => {
  if (sprinting.active) {
    playerPosition.x -= (config.speed.sprinting * fpsMultiplyer)
  } else {
    playerPosition.x -= (config.speed.walking * fpsMultiplyer)
  }

  // if character is too close to the left of the screen
  if ((playerPosition.x + scenePosition.x) < maxLeft && scenePosition.x < 0) {
    scenePosition.x += maxLeft - (playerPosition.x + scenePosition.x);
    if (scenePosition.x > 0) scenePosition.x = 0
  }

  if (playerPosition.x < 0) playerPosition.x = 0;

  const x = Math.ceil(playerPosition.x / config.blockSize)
  let y1 = playerPosition.y / config.blockSize
  let y2 = y1 + player.height

  if (y1 % 1 === 0) y1 += 1

  const hasObstacles = obstacles.some((obj) => (
    obj.x === x && obj.y >= y1 && obj.y <= y2
  ));

  if (hasObstacles) {
    playerPosition.x = x * config.blockSize;
  }

  collectables = collectables.map((obj) => {
    if (obj.x === x && obj.y >= y1 && obj.y <= y2) {
      return { ...obj, collected: true }
    }

    return obj
  });

  return {
    playerPosition,
    scenePosition,
    collectables
  }
}

export const handleMoveRight: (
  frameData: FrameDataType,
  sceneData: SceneDataType,
) => FrameDataType = ({
  playerPosition,
  scenePosition,
  collectables
}, {
  maxRight,
  fpsMultiplyer,
  sceneWidth,
  obstacles,
  sprinting,
  player
}) => {
  if (sprinting.active) {
    playerPosition.x += (config.speed.sprinting * fpsMultiplyer)
  } else {
    playerPosition.x += (config.speed.walking * fpsMultiplyer)
  }

  // if character is too close to the right of the screen
  if ((playerPosition.x > (maxRight - scenePosition.x)) && ((window.innerWidth - scenePosition.x) < sceneWidth)) {
    scenePosition.x -= playerPosition.x - (maxRight - scenePosition.x);
  }

  if ((playerPosition.x + player.width) > sceneWidth) playerPosition.x = sceneWidth;

  const x = Math.ceil(playerPosition.x / config.blockSize) + 1
  let y1 = playerPosition.y / config.blockSize
  let y2 = y1 + player.height

  if (y1 % 1 === 0) y1 += 1

  const hasObstacles = obstacles.some((obj) => (
    obj.x === x && obj.y >= y1 && obj.y <= y2
  ));

  if (hasObstacles) {
    playerPosition.x = (x - player.width - 1) * config.blockSize;
  }

  collectables = collectables.map((obj) => {
    if (obj.x === x && obj.y >= y1 && obj.y <= y2) {
      return { ...obj, collected: true }
    }

    return obj
  });

  return {
    playerPosition,
    scenePosition,
    collectables
  }
}

export const handleJump: (
  frameData: FrameDataType,
  sceneData: SceneDataType,
) => FrameDataType = ({
  playerPosition,
  scenePosition,
  collectables
}, {
  fpsMultiplyer,
  obstacles,
  sprinting,
  sceneHeight,
  player
}) => {
  if (sprinting.active) {
    playerPosition.y += (config.speed.sprintJumping * fpsMultiplyer)
  } else {
    playerPosition.y += (config.speed.jumping * fpsMultiplyer)
  }

  const x1 = (playerPosition.x / config.blockSize) + 1
  // if x1 is a round number, no need to check the block beside
  // otherwise it might stop you from jumping due to an adjacent block above the player
  const x2 = (x1 % 1 === 0 ? (x1 - 1) : x1) + player.width
  const y = Math.ceil(playerPosition.y / config.blockSize) + player.height

  const hasObstacles = obstacles.some((obj) => (
    obj.y === y && obj.x >= Math.floor(x1) && obj.x <= x2
  ));

  if (hasObstacles) {
    playerPosition.y = (y - 1 - player.height) * config.blockSize;
  }

  collectables = collectables.map((obj) => {
    if (obj.y === y && obj.x >= Math.floor(x1) && obj.x <= x2) {
      return { ...obj, collected: true }
    }

    return obj
  });

  if (playerPosition.y > sceneHeight) playerPosition.y = sceneHeight;

  return {
    playerPosition,
    scenePosition,
    collectables
  }
}

export const handleFall: (
  frameData: FrameDataType,
  sceneData: SceneDataType,
) => FrameDataType = ({
  playerPosition,
  scenePosition,
  collectables
}, {
  fpsMultiplyer,
  obstacles,
  player
}) => {
  playerPosition.y -= (config.speed.falling * fpsMultiplyer)

  const x1 = (playerPosition.x / config.blockSize) + 1
  // if x1 is a round number, no need to check the block beside
  // otherwise it might stop you from jumping due to an adjacent block above the player
  const x2 = (x1 % 1 === 0 ? (x1 - 1) : x1) + player.width
  const y = Math.ceil(playerPosition.y / config.blockSize)

  const hasObstacles = obstacles.some((obj) => (
    obj.y === y && obj.x >= Math.floor(x1) && obj.x <= x2
  ));

  if (hasObstacles) {
    playerPosition.y = y * config.blockSize;
  }

  collectables = collectables.map((obj) => {
    if (obj.y === y && obj.x >= Math.floor(x1) && obj.x <= x2) {
      return { ...obj, collected: true }
    }

    return obj
  });

  if (playerPosition.y < 0) playerPosition.y = 0;

  return {
    playerPosition,
    scenePosition,
    collectables
  }
}

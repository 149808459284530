import { useState, useEffect } from "react"

export type KeypressType = {
  active: boolean,
  pressedAt: number
}

export const useKeyPress = (targetKey: string) => {
  // State for keeping track of whether key is pressed
  const [key, setKey] = useState<KeypressType>({ active: false, pressedAt: 0 });

  // Add event listeners
  useEffect(() => {
    const keydown = (ev: KeyboardEvent) => {
      if (!key.active && ev.code === targetKey) {
        setKey({ active: true, pressedAt: new Date().getTime() });
      }
    }

    const keyup = (ev: KeyboardEvent) => {
      if (key.active && ev.code === targetKey) {
        setKey({ ...key, active: false });
      }
    };

    window.addEventListener("keydown", keydown);
    window.addEventListener("keyup", keyup);

    return () => {
      window.removeEventListener('keydown', keydown);
      window.removeEventListener('keyup', keyup);
    };
  }, [key, targetKey]);

  return key;
}
